import {$dom} from '../../helpers/dom';
import is from 'is_js';
import SlimSelect from 'slim-select'
import Scrollbar from 'smooth-scrollbar';

export default function customSelect() {
  const customSelectSelector = '.js-custom-select';

  return {
    init() {
      $dom.callAll(customSelectSelector, select => {
        new SlimSelect({
          select,
          showSearch: false,
          beforeOpen() {
            const list = this.slim.list;
            if (!is.mobile()) Scrollbar.init(list);
          }
        })
      });
    }
  }
}
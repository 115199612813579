import {$dom} from '../../helpers/dom';
import variables from '../../variables';
import {$style} from '../../helpers/style';
import {$events} from '../../helpers/events';
import Waves from 'node-waves';
import smoothScrollbar from '../components/scrollbar';
import menu from '../components/menu';
import tabs from '../components/tabs';
import dropdown from '../components/dropdown';
import datepicker from '../components/datepicker'
import customSelect from '../components/customSelect';
import validateForm from '../components/validateForm';
import switchTypeInput from '../components/switch-type-password'; //todo redo later
import Model from './Model';
import is from 'is_js';
import {isElement, warn} from '../../helpers/_utilities';
import gsap from 'gsap';

export default class Page extends Model {

  constructor(options = {}) {

    super(options);

    this.exist = true;

    if (is.not.domNode(this.rootElement)) {
      if (this.options.debug) warn(`Instance Page with name "${this.name}" has no root element provided`, 'Page-class');
      this.exist = false;
      return;
    }

    this.defaults = {
      carousels: [],
      batch: true
    };

    this.options = Object.assign(this.defaults, options);

    this.headerIndent = this.headerIndent.bind(this);
    this.smoothScrollbar = smoothScrollbar();
    this.menu = menu();
    this.tabs = tabs();
    this.dropdowns = dropdown();
    this.selects = customSelect();
    this.datepickers = datepicker();
    this.validateForms = validateForm();
    this.switchTypeInput = switchTypeInput();
  }

  headerIndent(setMode = true) {
    $dom.callAll(`.${variables.classNames.indentFromHeaderTarget}`, target => {
      $style.remove(target, 'paddingTop');
      if (setMode) {
        gsap.set(target, {
          paddingTop: variables.dom.header.offsetHeight + 'px',
        })
      }
    });

    return this;
  }

  initWaves(duration = variables.carouselDefaultDuration / 1.5) {
    Waves.attach('.button--waves', ['waves-button']);

    Waves.init({duration});

    return this;
  }

  checkMobile() {
    if (is.mobile()) $dom.addClass(document.body, variables.classNames.mobile);
    else $dom.removeClass(document.body, variables.classNames.mobile);

    return this;
  }

  init() {
    $events.resize.on(this.headerIndent);

    this.menu.init();
    this.tabs.init();
    this.dropdowns.init();
    this.selects.init();
    this.datepickers.init();
    this.validateForms.init();
    this.switchTypeInput.init();

    if (!is.mobile()) {
      this.smoothScrollbar.initAll();
    } else {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 10) {
          $dom.addClass(document.body, variables.classNames.scrolled);
        } else {
          $dom.removeClass(document.body, variables.classNames.scrolled);
        }
      });
    }

    this
      .headerIndent()
      .initWaves()
      .checkMobile()
    ;


    super.init(this);
    return this;
  }

  destroy() {
    $events.resize.off(this.headerIndent);

    this.smoothScrollbar.destroy();
    this.dropdowns.destroy();

    this
      .headerIndent(false)
    ;

    super.init(this);
    return this;
  }
}
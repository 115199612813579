import {$dom} from '../helpers/dom';
import homePage from './pages/home/';
import partnersPage from './pages/partners/';
import programPage from "./pages/program/";
import aboutPage from "./pages/about/";
import authPage from "./pages/auth/";

$dom.ready(() => {

  const currentPage = [
    homePage,
    partnersPage,
    programPage,
    aboutPage,
    authPage
  ].filter(page => {
    if (page.exist) {
      window.currentPage = page;
      return true
    }
  })[0];

  currentPage && currentPage.init();
});
import {$events} from '../../helpers/events';
import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';
import {preventDefault} from '../../helpers/_utilities';
import Hammer from 'hammerjs';


export default function menu(
  classes = {
    buttonSelector: 'js-menu-mobile-btn',
    menuSelector: 'js-menu-mobile',
    openClass: 'menu-is-open'
  }
) {
  const cl = cl => `.${cl}`;
  const hummer = new Hammer.Manager(document.querySelector(`.${classes.menuSelector} .container`));
  const Swipe = new Hammer.Swipe({
    velocity: 0.1
  });

  function openMenu() {
    $style.set(document.documentElement, 'height', '100%');
    $style.set(document.body, 'height', '100%');
    $style.set(document.body, 'overflow', 'hidden');

    $dom.addClass(document.body, classes.openClass);
  }

  function closeMenu() {
    $style.remove(document.documentElement, 'height');
    $style.remove(document.body, 'height');
    $style.remove(document.body, 'overflow');

    $dom.removeClass(document.body, classes.openClass);
  }

  function init() {
    $events.delegate.on('tap click', cl(classes.buttonSelector), event => {
      preventDefault(event);

      if ($dom.hasClass(document.body, classes.openClass)) closeMenu();
      else openMenu();
    });

    hummer.add(Swipe);
    hummer.on('swipe', closeMenu);
  }

  return {
    init
  }
}
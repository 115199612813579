import {$dom} from '../../helpers/dom';
import {Datepicker} from 'vanillajs-datepicker';
import ru from 'vanillajs-datepicker/js/i18n/locales/ru';

Object.assign(Datepicker.locales, ru);

export default function datepicker() {
    const
        datepickerSelector = '[data-datepicker]',
        containerSelector = '[data-datepicker-container]'
    ;

    return {
        init() {
            $dom.callAll(datepickerSelector, (datepicker, index) => {
                const container = $dom.getParent(datepicker, containerSelector);

                $dom.attr(container, {'data-datepicker-id': index});

                const DatepickerInstance = new Datepicker(datepicker, {
                    container: `${containerSelector}[data-datepicker-id="${index}"]`,
                    format: 'dd.mm.yyyy',
                    language: 'ru',
                    // minDate: 'today',
                    todayHighlight: true,
                    autohide: true,
                    prevArrow: '<svg class="svg-icon" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                        '<rect x="1.49951" y="4.74927" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(45 1.49951 4.74927)"/>' +
                        '<rect x="0.745605" y="5.49927" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(-45 0.745605 5.49927)"/>' +
                        '</svg>',
                    nextArrow: '<svg class="svg-icon" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                        '<rect x="4.50049" y="5.25073" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(-135 4.50049 5.25073)"/>' +
                        '<rect x="5.25439" y="4.50073" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(135 5.25439 4.50073)"/>' +
                        '</svg>',
                });
            });
        }
    }
}
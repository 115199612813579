import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function initHomeIntroAnimations() {
  gsap.timeline({
    scrollTrigger: {
      trigger: '.js-animations--home-intro',
      markers: false,
      start: 0,
      end: `+=${window.innerHeight}`,
      scrub: true
    }
  })
    .fromTo('.js-animations--home-intro-content', {
      yPercent: 0,
      autoAlpha: 1
    }, {
      yPercent: 15,
      autoAlpha: 0
    })
    .fromTo('.js-animations--home-intro-image', {
      yPercent: 0,
      autoAlpha: 1
    }, {
      yPercent: 15,
      autoAlpha: 0
    }, 0);
}
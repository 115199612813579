const variables = {
  $EXTERNAL_API_NAME: '$API',
  resizeDebounce: 100,
  debugLogs: true,
  customEventNames: {
    resize: 'resize-done',
    animateEnd: 'animate-end',
    modal: {
      opened: 'modal-opened',
      open: 'modal-open',
      close: 'modal-close',
      closed: 'modal-close-end'
    }
  },
  classNames: {
    mobile: 'is-mobile',
    modalOpen: 'modal-is-open',
    lazyLoading: 'is-loading',
    disabled: 'is-disabled',
    active: 'is-active',
    opened: 'is-opened',
    visible: 'is-visible',
    scrolled: 'is-scrolled',
    initialized: 'is-initialized',
    lazyPreloader: 'lazy__preloader',
    lazyImage: 'lazy__image',
    lazyLoaded: 'is-loaded',
    loaded: 'is-loaded',
    indentFromHeaderTarget: 'js-indent-from-header-target',
    dropdownTarget: 'js-dropdown',
    smoothScrollTarget: 'js-smooth-scroll-target',
    batchTarget: 'js-scroll-batch-element'
  },
  carouselDefaultDuration: 600,
  get windowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  },
  get windowHeight() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  },
  scrollbarWidth:	window.innerWidth - document.documentElement.clientWidth,
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  dom: {
    header: document.getElementById('js-header'),
    footer: document.getElementById('js-footer'),
  }
};

variables.defaultSwiperLazyOptions = {
  loadPrevNext: false,
  preloaderClass: variables.classNames.lazyPreloader,
  elementClass: variables.classNames.lazyImage,
  loadedClass: variables.classNames.lazyLoaded,
  loadingClass: variables.classNames.lazyLoading
};

export default variables;

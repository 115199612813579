import {$dom} from '../../helpers/dom';
import {$events} from "../../helpers/events";
import variables from '../../variables';

export default function switchTypeInput() {
  const
    buttonSelector = '[data-switch-type-button]',
    inputSelector = '[data-switch-type-input]'
  ;

  return {
    init() {
      $events.delegate.on('tap click', buttonSelector, function () {
        const button = this;
        const label = button.closest('label');
        const input = $dom.get(inputSelector, label);

        if (input === null) return false;

        if ($dom.hasClass(button, variables.classNames.visible)) {
          $dom.removeClass(button, variables.classNames.visible);
          $dom.attr(input, {'type': 'password'});

        } else {
          $dom.addClass(button, variables.classNames.visible);
          $dom.attr(input, {'type': 'text'});
        }
      });
    }
  }
}
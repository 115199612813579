import {$dom} from '../../helpers/dom';
import Bouncer from 'formbouncerjs'

export default function validateForm() {
  const validateFormSelector = '[data-form-validate]';

  return {
    init() {
      $dom.callAll(validateFormSelector, form => {
        new Bouncer(validateFormSelector, {
          messageAfterField: true,
          messages: {
            missingValue: {
              default: 'Поле обязательно для заполнения',
              select: 'Пожалуйста, выберите значение',
              checkbox: 'Пожалуйста, подтвердите Ваше согласие'
            },
            patternMismatch: {
              email: 'Пожалуйста, введите корректный e-mail',
              number: 'Пожалуйста, введите номер'
            },
            wrongLength: {
              over: 'Длина поля должна быть не больше {maxLength} символов',
              under: 'Длина поля должна быть не меньше {minLength} символов'
            }
          }
        });
      });
    }
  }
}
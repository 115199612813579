import variables from '../../variables';
import {preventDefault} from '../../helpers/_utilities';
import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';
import {$events} from '../../helpers/events';

export default function dropdown() {
    const dropdownSelector = `.${variables.classNames.dropdownTarget}`;

    const toggle = function (event) {
        preventDefault(event);

        const
            button = this,
            collapsedText = button.dataset.collapsedText,
            expandedText = button.dataset.expandedText,
            dropdown = button.closest(dropdownSelector),
            content = $dom.get('[data-dropdown-content]', dropdown)
        ;

        $style.slideToggle(content, {
            onDownStart() {
                $dom.addClass(dropdown, variables.classNames.active);
                if (collapsedText) $dom.html(button, collapsedText);
            },
            onUpStart() {
                $dom.removeClass(dropdown, variables.classNames.active);
                $dom.removeClass(dropdown, variables.classNames.opened);
                if (expandedText) $dom.html(button, expandedText)
            },
            onDownEnd() {
                $dom.addClass(dropdown, variables.classNames.opened);
            }
        });
    };

    return {
        init() {
            $dom.addClass(dropdownSelector, variables.classNames.initialized);

            $dom.callAll(dropdownSelector, dropdown => {
                if ($dom.hasClass(dropdown, variables.classNames.active)) {
                    const
                        content = $dom.get('[data-dropdown-content]', dropdown),
                        button = $dom.get('[data-dropdown-button]', dropdown),
                        collapsedText = button.dataset.collapsedText
                    ;

                    $style.slideToggle(content, {
                        onDownStart() {
                            if (collapsedText) $dom.html(button, collapsedText);
                        }
                    });
                }
            });

            $events.delegate.on('tap click', '[data-dropdown-button]', toggle);
        },
        destroy() {
            $dom.removeClass(dropdownSelector, variables.classNames.initialized);
            $events.delegate.off('tap click', '[data-dropdown-button]', toggle)
        }
    }

}